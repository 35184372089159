<template>
    <div class="mianbox">
        <div class="topbox">

           <div class="topbox_left">
               <i class="el-icon-d-arrow-left" @click="returntop()"></i>
                {{ basicdata.Code }} （{{basicdata.Name}}）
           </div>
           <div class="topbox_center">
                创建时间:{{ basicdata.CreateTime }}
           </div>
        </div>

        <div class="Basicinforbox">
            <el-descriptions v-if='!editstate'>    
                <el-descriptions-item label="项目名称">{{basicdata.Name}}</el-descriptions-item>
                <el-descriptions-item label="出包方">{{basicdata.SellCompany}}</el-descriptions-item>
                <el-descriptions-item label="经办部门">{{basicdata.DepartmentName}}</el-descriptions-item>              
                <el-descriptions-item label="主经办人">{{basicdata.PrincipalName}}</el-descriptions-item>
                <el-descriptions-item label="协办人员" >{{basicdata.AssistantsName}}</el-descriptions-item>
      
                <el-descriptions-item label="本金">{{ basicdata.Cost>=1000?basicdata.Cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','):basicdata.Cost}} 元</el-descriptions-item>
                <el-descriptions-item label="利息">{{  basicdata.Interest>=1000?basicdata.Interest.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','):basicdata.Interest }} 元</el-descriptions-item>
                <el-descriptions-item label="其它">{{  basicdata.DefaultInterest>=1000?basicdata.DefaultInterest.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','):basicdata.DefaultInterest}} 元</el-descriptions-item>
                <el-descriptions-item label="抵债金额">{{basicdata.PledgeAssetPrice>=1000?basicdata.PledgeAssetPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','):basicdata.PledgeAssetPrice}} 元</el-descriptions-item>
                <el-descriptions-item label="总规模">{{  basicdata.Scale>=1000?basicdata.Scale.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','):basicdata.Scale}} 元</el-descriptions-item>

                <el-descriptions-item label="购入价">{{  basicdata.BidPrice>=1000?basicdata.BidPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','):basicdata.BidPrice }} 元</el-descriptions-item>
                <el-descriptions-item label="购入评估价">{{basicdata.BuyAssessPrice>=1000?basicdata.BuyAssessPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','):basicdata.BuyAssessPrice }} 元</el-descriptions-item> 
                <el-descriptions-item label="处置价">{{  basicdata.ReducedPrice>=1000?basicdata.ReducedPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','):basicdata.ReducedPrice }} 元</el-descriptions-item>       

                <el-descriptions-item label="中介机构">{{basicdata.CompanysName}}</el-descriptions-item>
                <el-descriptions-item label="评估总价">{{  basicdata.ComputePrice }} 万元</el-descriptions-item>
                <el-descriptions-item label="变现价值">{{  basicdata.RealizableValue }} 万元</el-descriptions-item>
                <el-descriptions-item label="变现税费">{{  basicdata.RealizableTax }} 万元</el-descriptions-item>
                <el-descriptions-item label="清算价值">{{  basicdata.LiquidateValue }} 万元</el-descriptions-item>
                


                <el-descriptions-item label="其他说明">{{  basicdata.Remark }} </el-descriptions-item>
           
         </el-descriptions>
            <el-form ref="basicdata" :model="basicdata" label-width="120px" :rules="rules" class="formbox" size='medium' v-if='editstate'>
                <el-row  class="hangbox" :gutter="20">
                    <el-col :span="8" >
                        <el-form-item label="项目名称" prop="Name">
                            <el-input v-model="basicdata.Name" placeholder="请填写项目名称"></el-input>
                        </el-form-item>

                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="出包方" prop="SellCompany" >
                            <el-autocomplete
                                style="width:100%"
                                v-model="basicdata.SellCompany"
                                :fetch-suggestions="querySearch"
                                value-key=Name
                                placeholder="请输入出包方"
                                @select="handleSelect"
                            ></el-autocomplete>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" >
                        <el-form-item label="经办部门" prop="Department">
                            <el-cascader
                                placeholder="请选择上级部门"
                                :options="departmentlist"
                                v-model="basicdata.Department"
                                filterable
                                style="width:100%"
                                :props="{ expandTrigger: 'hover' }"
                                @change="getuserlist(basicdata.Department)"
                            >
                            </el-cascader>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row class="hangbox" :gutter="20">
                    <el-col :span="8" >
                        <el-form-item label="主经办人" prop="PrincipalId">
                            <el-select                            
                                v-model="basicdata.PrincipalId" 
                                filterable 
                                clearable                  
                                placeholder="请选择主经办人" 
                                style="width:100%">                             
                                <el-option
                                    v-for="item in operator_alist"
                                    :key="item.uID"
                                    :label="item.uRealName"
                                    :value="item.uID">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" >
                        <el-form-item label="协办人员" prop="AssistantsArr" >
                            <el-select 
                                v-model="basicdata.AssistantsArr" 
                                multiple
                                filterable 
                                allow-create
                                default-first-option
                                placeholder="请选择协办人员" 
                                style="width:100%">
                                <el-option
                                    v-for="item in operator_blist"
                                    :key="item.uID"
                                    :label="item.uRealName"
                                    :value="item.uID">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col> 
                </el-row>
                <el-row class="hangbox" :gutter="20">
                    <el-col :span="8" >
                        <el-form-item label="本金(元)" prop="Cost">
                            <div @mouseover="ShowPriceTxet1(1)">
                                <el-tooltip class="item" effect="dark" placement="top">
                                    <div slot="content" >{{costtip}}<br/>{{costtip2}}</div>
                                    <el-input v-model="basicdata.Cost" oninput="value=value.replace(/[^0-9.]/g,'')" placeholder="请输入本金"  @input="AdScale1(1)"></el-input>
                                </el-tooltip>
                            </div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" >
                        <el-form-item label="利息(元)" prop="Interest" >
                            <div @mouseover="ShowPriceTxet1(2)">
                                <el-tooltip class="item" effect="dark" placement="top">
                                    <div slot="content" >{{costtip}}<br/>{{costtip2}}</div>
                                    <el-input v-model="basicdata.Interest"  oninput="value=value.replace(/[^0-9.]/g,'')" placeholder="请输入利息"  @input="AdScale1(2)"></el-input>
                                </el-tooltip>
                            </div>
                        </el-form-item>                       
                    </el-col>
                    <el-col :span="8" >
                        <el-form-item label="其它(元)" prop="DefaultInterest">
                            <div @mouseover="ShowPriceTxet1(3)">
                                <el-tooltip class="item" effect="dark" placement="top">
                                    <div slot="content" >{{costtip}}<br/>{{costtip2}}</div>
                                    <el-input v-model="basicdata.DefaultInterest" oninput="value=value.replace(/[^0-9.]/g,'')" placeholder="请输入罚息" @input="AdScale1(3)"></el-input>
                                </el-tooltip>
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row class="hangbox" :gutter="20">
                    <el-col :span="8" >
                        <el-form-item label="抵债金额(元)"  prop="PledgeAssetPrice">
                            <div @mouseover="ShowPriceTxet1(4)">
                                <el-tooltip class="item" effect="dark" placement="top">
                                    <div slot="content" >{{costtip}}<br/>{{costtip2}}</div>
                                    <el-input v-model="basicdata.PledgeAssetPrice" placeholder="请输入抵债金额" type="number"
                                    oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}"
                                    @input="AdScale1(4)"></el-input>
                                </el-tooltip>
                            </div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" >
                        <el-form-item label="总规模(元)" prop="Scale">
                            <el-input v-model="basicdata.Scale"  oninput="value=value.replace(/[^0-9.]/g,'')" placeholder="请输入总规模" @input="UpScale1(newform.Scale)"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>               
                <el-row class="hangbox" :gutter="20">
                    <el-col :span="24" >
                         <el-form-item label="中介机构" prop="CompanysArr" >
                            <el-select 
                                v-model="basicdata.CompanysArr" 
                                multiple
                                filterable 
                                allow-create
                                default-first-option
                                placeholder="请选择中介机构" 
                                style="width:100%">
                                <el-option
                                    v-for="item in agencylist"
                                    :key="item.Id"
                                    :label="item.Name"
                                    :value="item.Id">
                                </el-option>
                            </el-select> 
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row class="hangbox">
                    <el-col :span="24" >
                        <el-form-item label="其他说明" prop="Remark">
                            <el-input v-model="basicdata.Remark" type="textarea" placeholder="如有其他说明请填写"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div>
                <el-button type="primary" size="small" v-if='!editstate' @click="edit()">编辑</el-button>
                <el-button type="primary" size="small" v-if='editstate' @click="preservation('basicdata')">保存</el-button>
                 <el-button size="small" v-if='editstate' @click="cancelbj()">取消</el-button>
            </div>
        </div>
        <!--新建资产户弹窗-->
        <el-dialog title="新建债权(户)" :visible.sync="dialogFormVisible" width="80%" :before-close="AssethandleClose">
            <el-form ref="newform" :model="newform" label-width="100px" :rules="rulesnow" label-position="top" >
                <el-row :gutter="20">
                    <el-col :span="8" >
                        <el-form-item label="原债权人" prop="Name">
                            <el-input v-model="newform.Name" placeholder="请填写原债权人"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" >
                        <el-form-item label="贷款主体" prop="LoansMain">
                            <el-input v-model="newform.LoansMain" placeholder="请填写贷款主体"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" >
                        <el-form-item label="原借款本金(元)" prop="Cost">
                            <div @mouseover="ShowPriceTxet(1)">
                                <el-tooltip class="item" effect="dark" placement="top">
                                    <div slot="content" >{{costtip}}<br/>{{costtip2}}</div>
                                <el-input v-model="newform.Cost" placeholder="请填写原借款本金" type="number"
                                oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}"
                                @input="AdScale(1)"></el-input>
                                </el-tooltip>
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row  :gutter="20">
                    <el-col :span="8" >
                        <el-form-item label="现贷款本金余额(元)" prop="Balance">
                            <div @mouseover="ShowPriceTxet(2)">
                                <el-tooltip class="item" effect="dark" placement="top">
                                    <div slot="content" >{{costtip}}<br/>{{costtip2}}</div>
                                    <el-input v-model="newform.Balance" placeholder="请填写现贷款本金余额" type="number"
                                    oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}"
                                    @input="AdScale(2)"></el-input>
                                </el-tooltip>
                            </div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" >
                        <el-form-item label="贷款利息(元)" prop="Interest">
                            <div @mouseover="ShowPriceTxet(3)">
                                <el-tooltip class="item" effect="dark" placement="top">
                                    <div slot="content" >{{costtip}}<br/>{{costtip2}}</div>
                                    <el-input v-model="newform.Interest" placeholder="请填写贷款利息" type="number"
                                    oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}"
                                    @input="AdScale(3)"></el-input>
                                </el-tooltip>
                            </div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" >
                        <el-form-item label="其它(元)" prop="DefaultInterest">
                            <div @mouseover="ShowPriceTxet(4)">
                                <el-tooltip class="item" effect="dark" content="罚息、垫付费用、诉讼等其它费用" placement="bottom">
                                    <el-tooltip class="item" effect="dark" placement="top">
                                        <div slot="content" >{{costtip}}<br/>{{costtip2}}</div>
                                        <el-input v-model="newform.DefaultInterest" placeholder="请填写其它" type="number"
                                            oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}"
                                            @input="AdScale(4)"></el-input>
                                    </el-tooltip>
                                </el-tooltip>
                           </div>    
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" >
                        <el-form-item label="抵债资产类型" prop="PledgeAssetType">
                            <el-select 
                                v-model="newform.PledgeAssetType" 
                                filterable 
                                placeholder="请选择抵债资产类型" 
                                style="width:100%"
                                @change="PledgeAssetTypeChange(newform.PledgeAssetType)">
                                <el-option
                                    v-for="item in pledgeAssetlist"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8"  v-if="newform.PledgeAssetType==1">
                        <el-form-item label="抵债金额(元)">
                            <div @mouseover="ShowPriceTxet(5)">
                                <el-tooltip class="item" effect="dark" placement="top">
                                    <div slot="content" >{{costtip}}<br/>{{costtip2}}</div>
                                    <el-input v-model="newform.PledgeAssetPrice" placeholder="请填写抵债金额" type="number"
                                    oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}"
                                    @input="AdScale(5)"></el-input>
                                </el-tooltip>
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row  :gutter="20">
                    <el-col :span="8" >
                        <el-form-item label="债权总额(元)" prop="TotalAmount">
                            <el-input v-model="newform.TotalAmount" placeholder="请填写债权总额" type="number"
                            oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}"
                            @input="UpScale(newform.TotalAmount)"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" >
                        <el-form-item label="担保分类" prop="GuaranteeTypeArr">
                            <el-select 
                                v-model="newform.GuaranteeTypeArr" 
                                multiple
                                filterable
                                allow-create
                                default-first-option 
                                placeholder="请选择担保分类" 
                                style="width:100%">
                                <el-option
                                    v-for="item in guaranteelist"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" >
                        <el-form-item label="抵押人" prop="HypothecateArr"> 
                           <el-tooltip class="item" effect="dark" content="非常重要：填写完成后按回车确定" placement="top">
                           <el-select
                                v-model="newform.HypothecateArr"
                                multiple
                                filterable
                                allow-create
                                default-first-option
                                placeholder="请填写抵押人,填写一个按回车后填写下一个"
                                style="width:100%">
                                <el-option
                                    v-for="item in Hypothecatelist"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-tooltip>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row  :gutter="20">
                    <el-col :span="8" >
                        <el-form-item label="质押人" prop="PledgerArr"> 
                           <el-tooltip class="item" effect="dark" content="非常重要：填写完成后按回车确定" placement="top">
                           <el-select
                                v-model="newform.PledgerArr"
                                multiple
                                filterable
                                allow-create
                                default-first-option
                                placeholder="请填写抵、质押人,填写一个按回车后填写下一个"
                                style="width:100%">
                                <el-option
                                    v-for="item in Pledgelist"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-tooltip>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" >
                        <el-form-item label="保证人" prop="GuarantorArr">
                            <el-tooltip class="item" effect="dark" content="非常重要：填写完成后按回车确定" placement="top">
                            <el-select
                                v-model="newform.GuarantorArr"
                                multiple
                                filterable
                                allow-create
                                default-first-option
                                placeholder="请填写保证人,填写一个按回车后填写等等下一个"
                                style="width:100%">
                                <el-option
                                    v-for="item in guarantorlist"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-tooltip>
                        </el-form-item>
                    </el-col> 
                    <el-col :span="8" >
                        <el-form-item label="司法进度" prop="Status">
                            <el-select 
                                v-model="newform.Status" 
                                filterable 
                                placeholder="请选择司法进度" 
                                style="width:100%">
                                <el-option
                                    v-for="item in judiciallist"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row  :gutter="20">
                    <el-col :span="8">
                        <el-form-item label="购入评估价(元)">
                            <div @mouseover="ShowPriceTxet(6)">
                                <el-tooltip class="item" effect="dark" placement="top">
                                    <div slot="content" >{{costtip}}<br/>{{costtip2}}</div>
                                    <el-input v-model="newform.BuyAssessPrice" placeholder="请填写购入评估价" type="number"
                                    oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}"
                                    @input="AdScale(6)"></el-input>
                                </el-tooltip>
                            </div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" >
                        <el-form-item label="购入价(元)" >
                            <div @mouseover="ShowPriceTxet(7)">
                                <el-tooltip class="item" effect="dark" placement="top">
                                    <div slot="content" >{{costtip}}<br/>{{costtip2}}</div>
                                    <el-input v-model="newform.BidPrice" placeholder="请输入购入价" type="number"
                                    oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}"
                                    @input="AdScale(7)"></el-input>
                                </el-tooltip>
                            </div>
                        </el-form-item>
                    </el-col> 
                    <el-col :span="8">
                        <el-form-item label="优先受偿金额(元)">
                            <div @mouseover="ShowPriceTxet(8)">
                                <el-tooltip class="item" effect="dark" content="优先于本债权的其他法定优先受偿款（包括工程款、应缴纳的税金、优先于本债权的抵押权等其他法定优先受偿款）" placement="bottom">
                                <el-tooltip class="item" effect="dark" placement="top">
                                    <div slot="content" >{{costtip}}<br/>{{costtip2}}</div>
                                    <el-input v-model="newform.CompensationPrice" placeholder="请填写优先受偿金额" type="number"
                                    oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}"
                                    @input="AdScale(8)"></el-input>
                                </el-tooltip>
                            </el-tooltip>
                            </div>
                        </el-form-item>
                    </el-col>                   
                </el-row>
                <el-row  :gutter="20">
                    <el-col :span="24" >
                        <el-form-item label="备注信息" prop="Remarks">
                            <el-input v-model="newform.Remarks" type="textarea" placeholder="如有其他说明请填写"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel()">取 消</el-button>
                <el-button type="primary" @click="determine('newform')">确 定</el-button>
            </div>
        </el-dialog>
        <div class="householdlistbox">
            <div class="nowtopbox">
                <div class="biaoti">
                    债权(户)列表
                </div>
                <div>
                    <el-button type="primary" size="small"  @click="derive()">导出债券户信息</el-button>
                <el-button type="primary" size="small"  @click="newbuild()">新建债权 (户)</el-button>
                </div>
             
            </div>
            <div class="listbox">
                <el-table
                :data="tableData" 
                border
                @selection-change="handleSelectionChange"
                :style="{width: '100%','margin-bottom':'2rem'}"
                :header-cell-style="{background:'#f5f7fa'}">
                    <el-table-column v-for="(item, index) in tableList" :key="index" :prop="item.prop" :label="item.label" :type="item.type"></el-table-column>
                    <el-table-column
                        fixed="right"
                        label="操作"
                        width="120">
                        <template slot-scope="scope">
                            <el-button @click="handleClick(scope.row)" type="text" size="small">查看</el-button>
                            <el-button type="text" size="small" @click="handleDelete(scope.$index, scope.row)" slot="reference">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                    @current-change="handleCurrentChange"
                    :current-page="paging.currentPage"
                    :page-sizes="[10]"
                    :page-size="paging.size"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="paging.total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    //import { mapState, mapMutations } from 'vuex';
    import { instance ,addSysOperateLog} from "../../../apis/apis.js";
    import { changeToChinese } from '../../../../util/helps';
    export default {
        data(){
            return{
                rulesnow: {
                    //原债权人
                    Name: [
                        { required: true, message: '请输入原债权人', trigger: 'blur' },
                    ],
                    //贷款主体
                    LoansMain: [
                        { required: true, message: '请输入贷款主体', trigger: 'blur' },
                    ],
                    //原借款本金
                    Cost: [
                        { required: true, message: '请输入原借款本金', trigger: 'blur' }, 
                        
                    ],
                    //现贷款本金余额
                    Balance: [
                        { required: true, message: '请输入现贷款本金余额', trigger: 'blur' }, 
                    ],
                    //贷款利息
                    Interest: [
                        { required: true, message: '请输入贷款利息', trigger: 'blur' }, 
                    ],
                    //其它
                    DefaultInterest: [
                        { required: true, message: '请输入其它', trigger: 'blur' }, 
                    ],
                    //债权总额
                    TotalAmount: [
                        { required: true, message: '请输入债权总额', trigger: 'blur' }, 
                    ],
                    //担保分类
                    GuaranteeTypeArr: [
                        { required: true, message: '请选择担保分类', trigger: 'blur' },
                    ],
                    //抵、质押人
                    Pledger: [
                        { required: true, message: '请填写抵、质押人', trigger: 'blur' },
                    ],
                    //保证人
                    Guarantor: [
                        { required: true, message: '请填写保证人', trigger: 'blur' },
                    ], 
                    //司法进度
                    Status: [
                        { required: true, message: '请选择司法进度', trigger: 'blur' },
                    ],
                    //抵债资产类型
                    PledgeAssetType: [
                        { required: true, message: '请选择抵债资产类型', trigger: 'blur' },
                    ],
                    //垫付金额
                    PaymentAccount: [
                        { required: true, message: '请输入垫付金额', trigger: 'blur' }, 
                    ],
                    //购入价
                    BidPrice: [
                        { required: false, message: '请输入购入价', trigger: 'blur' }, 
                    ],
                    //处置价
                    ReducedPrice: [
                        { required: false, message: '请输入处置价', trigger: 'blur' }, 
                    ],
                    //备注信息
                    Remarks: [
                        { required: false, message: '请输入备注信息', trigger: 'blur' },
                    ],
                     //抵、质押人
                     HypothecateArr: [
                        { required: true, message: '请选择抵押人', trigger: 'blur' },
                    ],
                      //抵、质押人
                      PledgerArr: [
                        { required: true, message: '请选择质押人', trigger: 'blur' },
                    ],
                     //保证人
                     GuarantorArr: [
                        { required: true, message: '请选择保证人', trigger: 'blur' },
                    ],
                },
                newform: {
                    AssetProjectId:'',//资产包编号
                    Name: '',//原债权人
                    LoansMain: '',//贷款主体
                    Cost: '',//原借款本金
                    Balance: '',//现贷款本金余额
                    Interest: '',//贷款利息
                    DefaultInterest: '',//其它
                    PledgeAssetPrice:'',//抵债金额
                    TotalAmount: '',//债权总额
                    GuaranteeTypeArr: [],//担保分类
                    HypothecateArr:[],//抵押人
                    PledgerArr: [],//质押人
                    GuarantorArr: [],//保证人 
                    Status: [],//司法进度
                    PledgeAssetType:[],//抵债资产类型
                    PaymentAccount: '',//垫付金额
                    BidPrice: '',//购入价
                    ReducedPrice: '',//处置价
                    Remarks: '',//备注信息
                    GuaranteeType:'',
                    Hypothecate:''
                },  
                restaurants: [],
                editstate:false,
                //新建户
                dialogFormVisible: false,
                basicdata: {},
                cankao2:{
                    Name: '项目名称',//项目名称
                    SellCompany:'机构2',//出包方
                    SellCompanyId:2,//出包方的ID
                    department:[1002,1003],//经办部门路径
                    DepartmentId:1003,//经办部门的id
                    PrincipalId:1002,//主经办人
                    AssistantId:[1002],//协办人员
                    Scale:254,//总规模
                    Cost:254,//本金
                    Interest:254,//利息
                    DefaultInterest:254,//罚息 
                    CompanysId:[1,3],//中介机构
                    Remark:'dwe',//其他说明
                    IsDeleted: false,
                    BidPrice:0,//购置价格
                    ReducedPrice:0//处置价格
                },
                rules: {
                    Name: [
                        { required: true, message: '请输入项目名称', trigger: 'blur' },
                    ],
                    SellCompany: [
                        { required: true, message: '请输入出包方',},
                    ],
                    department: [
                        { required: true, message: '请选择经办部门', trigger: 'blur' },
                    ],
                    PrincipalId: [
                        { required: true, message: '请选择主经办人', trigger: 'blur' },
                    ],
                    AssistantId: [
                        { required: true, message: '请选择协办人员', trigger: 'blur' },
                    ],
                    Scale: [
                        { required: true, message: '请输入总规模', trigger: 'blur' }, 
                    ],
                    Cost: [
                        { required: true, message: '请输入本金', trigger: 'blur' }, 
                    ],
                    Interest: [
                        { required: true, message: '请输入利息', trigger: 'blur' }, 
                    ],
                    PledgeAssetPrice: [
                        { required: true, message: '请输入抵债金额', trigger: 'blur' }, 
                    ],
                    DefaultInterest: [
                        { required: true, message: '请输入罚息', trigger: 'blur' }, 
                    ],
                    BidPrice: [
                        { required: true, message: '请输入购入价', trigger: 'blur' }, 
                    ],
                    ReducedPrice: [
                        { required: true, message: '请输入处置价', trigger: 'blur' }, 
                    ], 
                    Companys: [
                        { required: true, message: '请选择中介机构', trigger: 'blur' },
                    ],
                    Remark: [
                        { required: false, message: '请输入其他说明', trigger: 'blur' },
                    ],

                },
                //列表展示的值
                tableList:[
                    { 
                        type:"selection"
                    },
                    {
                        prop:'Code',
                        label:'户系统编号'
                    },
                    {
                        prop:'LoansMain',
                        label:'贷款主体'
                    },
                    {
                        prop:'Balance',
                        label:'现贷款本金余额(元)'
                    },
                    {
                        prop:'Interest',
                        label:'贷款利息(元)'
                    },
                    {
                        prop:'GuaranteeType',
                        label:'担保分类'
                    },
                    {
                        prop:'Hypothecate',
                        label:'抵押人'
                    },
                    {
                        prop:'Pledger',
                        label:'质押人'
                    },
                    {
                        prop:'Guarantor',
                        label:'保证人'
                    },
                   
                    {
                        prop:'Status',
                        label:'司法进度'
                    },
                    
                    {
                        prop:'ComputePrice',
                        label:'评估市场价值(万元)'
                    },
                ],
                //抵押人列表
                Hypothecatelist:[],
                //质押人列表
                Pledgelist:[],
                //保证人列表
                guarantorlist:[],
                //担保分类列表
                guaranteelist:[
                    {
                        value: '1',
                        label: '抵押'
                    },{
                        value: '2',
                        label: '质押'
                    },{
                        value: '3',
                        label: '保证'
                    },{
                        value: '0',
                        label: '未知'
                    }
                ],
                //抵债资产类型
                pledgeAssetlist:[
                    {
                        value: '1',
                        label: '抵债资产'
                    },{
                        value: '2',
                        label: '非抵债资产'
                    },
                ],
                //司法进度列表
                judiciallist:[
                    {
                        value: '1',
                        label: '司法立案，未开庭'
                    },{
                        value: '2',
                        label: '司法立案，已开庭（已判决）'
                    },{
                        value: '3',
                        label: '司法立案，已开庭（未判决）'
                    },{
                        value: '4',
                        label: '已判决，未上诉'
                    },{
                        value: '5',
                        label: '已判决，上诉中'
                    },{
                        value: '6',
                        label: '执行中'
                    },{
                        value: '7',
                        label: '未诉（已办理赋强文书）'
                    },{
                        value: '8',
                        label: '终本'
                    },{
                        value: '0',
                        label: '其它'
                    }
                ],
                 
                 //表单数据
                tableData: [ ],
                 //分页
                paging:{
                    currentPage:1,//当前加载页
                    size:10,//每页显示条目个数
                    total:400,//总条目数
                },
                //出包方列表
                Transferorlist:[
                    
                ],
                //经办部门列表
                departmentlist:[
                    
                ],
                //主经办人列表
                operator_alist:[
                    
                ],
                //协办人员列表
                operator_blist:[
                     
                ],             
                //中介机构列表
                agencylist:[],
                costtip:'0',
                costtip2:'',
                ids:[],
            }
        },
        created(){  
            this.getpackagedetail(this.$route.params.id)//获取资产包详情
            this.getorganlist(); //获取机构列表
            this.getdepartmentlist();//获取部门列表      
            this.getAeestFamilylist();   
            //this.getuserlist(this.basicdata.Department)
        },
        // computed: {
        //     ...mapState({ // 从 state 中的到的计算属性
        //         catch_components: state => state.catch_components,  // keepalive缓存
        //         tabList: state => state.tabList,  // tags菜单列表
        //         active: state => state.active, // 已选中菜单
        //     })
        // },
        methods:{
            derive(){
                if(this.ids.length<=0){
                    this.$message({
                        message: '至少勾选一列数据',
                        type: 'error'
                    });
                }else{
                    var time = new Date();
                    var nids=this.ids.join(",");
                    var text="债权户导出信息"+time.toLocaleString()+".xlsx";
                    instance.get('/AeestFamily/DownExecelFamily',{params: {ids:nids}, responseType: 'blob'}).then(res=>{
                        const blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                        const objectUrl = URL.createObjectURL(blob) // 创建URL
                        const link = document.createElement('a');
                        link.href = objectUrl;
                        // 这里是获取后台传过来的文件名
                        link.setAttribute('download',text)
                        link.click() // 下载文件
                        
                    });
                }
            },
            UpScale(value){  
               if(value>0){
                this.newform.Balance = 0;
                this.newform.Interest = 0;
                this.newform.DefaultInterest = 0;
                this.newform.PledgeAssetPrice=0;
               }
            },
            PledgeAssetTypeChange(val){
                if(val==2){
                    this.newform.PledgeAssetPrice=0;
                    this.AdScale();
                }
            },
            //关闭资产详细页
            AssethandleClose(done) {
                var _this = this;
                this.$confirm('确认关闭？')
                    .then(_ => {
                        //_this.getAssetsInfoList()
                        _this.$router.go(0)
                        done();
                    })
                    .catch(_ => { });
            },
            AdScale(index){ 
                var v = Number(this.newform.Balance) + Number(this.newform.Interest) +Number(this.newform.DefaultInterest)+Number(this.newform.PledgeAssetPrice); 
                if(v>0){ 
                    this.newform.TotalAmount=v.toString().match(/^\d+(?:\.\d{0,2})?/)[0];
                } 
                switch(index){
                    case 1: 
                        if(this.newform.Cost>0){ 
                            this.costtip =this.newform.Cost.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                            this.costtip2 = changeToChinese(this.newform.Cost)
                        } 
                        break;
                    case 2: 
                        if(this.newform.Balance>0){ 
                            this.costtip =this.newform.Balance.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                            this.costtip2 = changeToChinese(this.newform.Balance)
                        } 
                        break;
                    case 3: 
                        if(this.newform.Interest>0){ 
                            this.costtip =this.newform.Interest.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                            this.costtip2 = changeToChinese(this.newform.Interest)
                        } 
                        break;    
                    case 4: 
                        if(this.newform.DefaultInterest>0){ 
                            this.costtip =this.newform.DefaultInterest.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                            this.costtip2 = changeToChinese(this.newform.DefaultInterest)
                        } 
                        break;      
                    case 5: 
                        if(this.newform.PledgeAssetPrice>0){ 
                            this.costtip =this.newform.PledgeAssetPrice.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                            this.costtip2 = changeToChinese(this.newform.PledgeAssetPrice)
                        } 
                        break;    
                    case 6: 
                        if(this.newform.BuyAssessPrice>0){ 
                            this.costtip =this.newform.BuyAssessPrice.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                            this.costtip2 = changeToChinese(this.newform.BuyAssessPrice)
                        } 
                        break;    
                    case 7: 
                        if(this.newform.BidPrice>0){ 
                            this.costtip =this.newform.BidPrice.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                            this.costtip2 = changeToChinese(this.newform.BidPrice)
                        } 
                        break;      
                    case 8: 
                        if(this.newform.CompensationPrice>0){ 
                            this.costtip =this.newform.CompensationPrice.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                            this.costtip2 = changeToChinese(this.newform.CompensationPrice)
                        } 
                        break;     
                }      
            },
            UpScale1(value){  
               if(value>0){
                this.basicdata.Cost = 0;
                this.basicdata.Interest = 0;
                this.basicdata.DefaultInterest = 0;
                this.basicdata.PledgeAssetPrice=0;
               }
            },
            AdScale1(index){ 
                var v = Number( this.basicdata.Cost) + Number( this.basicdata.Interest) +Number( this.basicdata.DefaultInterest)+Number( this.basicdata.PledgeAssetPrice); 
                if(v>0){ 
                    this.basicdata.Scale=v.toString().match(/^\d+(?:\.\d{0,2})?/)[0];
                } 
                switch(index){
                    case 1: 
                        if(this.basicdata.Cost>0){ 
                            this.costtip =this.basicdata.Cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                            this.costtip2 = changeToChinese(this.basicdata.Cost)
                        } 
                        break;
                    case 2: 
                        if(this.basicdata.Interest>0){ 
                            this.costtip =this.basicdata.Interest.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                            this.costtip2 = changeToChinese(this.basicdata.Interest)
                        } 
                        break;
                    case 3: 
                        if(this.basicdata.DefaultInterest>0){ 
                            this.costtip =this.basicdata.DefaultInterest.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                            this.costtip2 = changeToChinese(this.basicdata.DefaultInterest)
                        } 
                        break;    
                    case 4: 
                        if(this.basicdata.PledgeAssetPrice>0){ 
                            this.costtip =this.basicdata.PledgeAssetPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                            this.costtip2 = changeToChinese(this.basicdata.PledgeAssetPrice)
                        } 
                        break;     
                }      
            },
            ShowPriceTxet1(index){
                switch(index){
                    case 1: 
                        if(this.basicdata.Cost>0){ 
                            this.costtip =this.basicdata.Cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                            this.costtip2 = changeToChinese(this.basicdata.Cost)
                        }else{
                            this.costtip='0';  
                            this.costtip2='';
                        } 
                        break;
                    case 2: 
                        if(this.basicdata.Interest>0){ 
                            this.costtip =this.basicdata.Interest.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                            this.costtip2 = changeToChinese(this.basicdata.Interest)
                        }else{
                            this.costtip='0';  
                            this.costtip2='';
                        }  
                        break;
                    case 3: 
                        if(this.basicdata.DefaultInterest>0){ 
                            this.costtip =this.basicdata.DefaultInterest.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                            this.costtip2 = changeToChinese(this.basicdata.DefaultInterest)
                        }else{
                            this.costtip='0';  
                            this.costtip2='';
                        }  
                        break;    
                    case 4: 
                        if(this.basicdata.PledgeAssetPrice>0){ 
                            this.costtip =this.basicdata.PledgeAssetPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                            this.costtip2 = changeToChinese(this.basicdata.PledgeAssetPrice)
                        }else{
                            this.costtip='0';  
                            this.costtip2='';
                        }  
                        break;     
                }  
            },
            //获取用户列表
            getuserlist(valve){
                var key = valve.slice(-1)
                instance.get('/User/GetDepartmentUser?did='+key).then(res=>{
                    if(res.data.success){
                        this.operator_alist=res.data.response
                        this.operator_blist=res.data.response 
                        if(this.operator_alist.length==0){
                            this.basicdata.PrincipalId=null;
                            this.basicdata.AssistantsArr=null;
                        } 
                           
                    }
                });
                var end = valve[valve.length-1]         
            },
            //获取债券户列表
            getAeestFamilylist(){
                instance.get('AeestFamily/Get?page='+this.paging.currentPage+'&projectid='+this.$route.params.id+'&intPageSize='+this.paging.size).then(res=>{
                    if(res.data.success){
                        this.tableData=res.data.response.data;        
                        this.tableData.forEach(item => {
                            if(item.Balance>=1000){
                                item.Balance= item.Balance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                            }
                            if(item.Interest>=1000){
                                item.Interest= item.Interest.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                            }
                            var tarr = item.GuaranteeType.split(",");
                            var pname =[];
                            this.guaranteelist.forEach(i => {
                                tarr.forEach(i2=>{
                                    if(i.value==i2){
                                        pname.push(i.label)
                                    }
                                }) 
                            });
                            item.GuaranteeType = pname.join(",");
                            if(item.Status!=null){                            
                                this.judiciallist.forEach(j=>{
                                    if(item.Status== j.value){
                                        item.Status=j.label;
                                    }
                                }); 
                            }
                            if(item.PledgeAssetType!=null){                            
                                this.pledgeAssetlist.forEach(j=>{
                                    if(item.PledgeAssetType== j.value){
                                        item.PledgeAssetType=j.label;
                                    }
                                }); 
                            }
                       
                        });  
                        this.paging.total=res.data.response.dataCount
                    }
                });
            },
             //获取机构列表
            getorganlist(){
                instance.get('/Companys/Get?page=1&intPageSize=500').then(res=>{
                    if(res.data.success){
                        this.agencylist=res.data.response.data
                        this.restaurants = res.data.response.data
                    }
                });
            },
            //获取部门列表
            getdepartmentlist(){
                instance.get('/Department/GetDepartmentTree').then(res=>{
                    if(res.data.success){
                        this.departmentlist=res.data.response.children 
                    }
                });
            },   
            ShowPriceTxet(index){   
                switch(index){
                    case 1: 
                        if(this.newform.Cost>0){ 
                            this.costtip =this.newform.Cost.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                            this.costtip2 = changeToChinese(this.newform.Cost)
                        }else{
                            this.costtip='0';  
                            this.costtip2='';
                        }
                        break;
                    case 2: 
                        if(this.newform.Balance>0){ 
                            this.costtip =this.newform.Balance.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                            this.costtip2 = changeToChinese(this.newform.Balance)
                        }else{
                            this.costtip='0';  
                            this.costtip2='';
                        }
                        break;
                    case 3: 
                        if(this.newform.Interest>0){ 
                            this.costtip =this.newform.Interest.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                            this.costtip2 = changeToChinese(this.newform.Interest)
                        }else{
                            this.costtip='0';  
                            this.costtip2='';
                        } 
                        break;    
                    case 4: 
                        if(this.newform.DefaultInterest>0){ 
                            this.costtip =this.newform.DefaultInterest.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                            this.costtip2 = changeToChinese(this.newform.DefaultInterest)
                        }else{
                            this.costtip='0';  
                            this.costtip2='';
                        } 
                        break;      
                    case 5: 
                        if(this.newform.PledgeAssetPrice>0){ 
                            this.costtip =this.newform.PledgeAssetPrice.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                            this.costtip2 = changeToChinese(this.newform.PledgeAssetPrice)
                        }else{
                            this.costtip='0';  
                            this.costtip2='';
                        } 
                        break;    
                    case 6: 
                        if(this.newform.BuyAssessPrice>0){ 
                            this.costtip =this.newform.BuyAssessPrice.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                            this.costtip2 = changeToChinese(this.newform.BuyAssessPrice)
                        } else{
                            this.costtip='0';  
                            this.costtip2='';
                        } 
                        break;    
                    case 7: 
                        if(this.newform.BidPrice>0){ 
                            this.costtip =this.newform.BidPrice.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                            this.costtip2 = changeToChinese(this.newform.BidPrice)
                        }else{
                            this.costtip='0';  
                            this.costtip2='';
                        } 
                        break;      
                    case 8: 
                        if(this.newform.CompensationPrice>0){ 
                            this.costtip =this.newform.CompensationPrice.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                            this.costtip2 = changeToChinese(this.newform.CompensationPrice)
                        }else{
                            this.costtip='0';  
                            this.costtip2='';
                        } 
                        break;   
                }  
            },        
             //出包方
            handleSelect(item) {
                this.basicdata.SellCompanyId=item.Id
            },
            querySearch(queryString, cb) {
                var restaurants = this.restaurants;
                var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
                // 调用 callback 返回建议列表的数据
                cb(results);
            },
            createFilter(queryString) {
                return (restaurant) => {
                     return (restaurant.Name.indexOf(queryString.toLowerCase()) === 0);
                };
            },
            //获取详情
            getpackagedetail(id){ 
                instance.get('/AssetProject/GetInfo?id='+id).then(res=>{
                    if(res.data.success){
                        this.basicdata=res.data.response;                
                        this.basicdata.RealizableValue = this.basicdata.RealizableValue==null?0: this.basicdata.RealizableValue;
                        this.basicdata.RealizableTax = this.basicdata.RealizableTax==null?0: this.basicdata.RealizableTax;
                        this.basicdata.LiquidateValue = this.basicdata.LiquidateValue==null?0: this.basicdata.LiquidateValue;
                        this.basicdata.ComputePrice = this.basicdata.ComputePrice==null?0: this.basicdata.ComputePrice;
                        if(this.basicdata.CompanysArr.length>0){
                            var companys =[]
                            this.basicdata.CompanysArr.forEach(item => {
                                companys.push(parseInt(item))
                            });
                            this.basicdata.CompanysArr = companys
                        } 
                        this.getuserlist(this.basicdata.Department)           
                    }
                });
            },
            //新建户
            newbuild(){
                this.dialogFormVisible=true
            },
            cancel(){
                this.dialogFormVisible=false
            }, 
            //债券户添加 
            determine(newform){ 
                this.newform.GuaranteeType =""
                this.$refs[newform].validate((valid) => {
                if (valid) {
                    this.newform.AssetProjectId=this.$route.params.id 
                    instance.post('/AeestFamily/Post',this.newform).then(res=>{ 
                        if(res.data.success){ 
                            this.$alert('债权（户）创建成功！', '创建成功', {
                                confirmButtonText: '确定',
                                callback: action => {
                                    this.$router.go(0) 
                                }
                            });
                          
                        }else{
                            this.$message.error('创建错误请联系管理员');
                        }  
                    });
                } else {
                    this.$message.error('请正确填写信息');
                    }
                });
            },
            edit(){
                this.editstate=true
            },
            returntop(){
                 var url =`/modules/promanage1/promanage`;
                this.$router.replace(url);
            },
            //编辑
            preservation(basicdata){
                this.editstate=false 
                
                this.$refs[basicdata].validate((valid) => {
                if (valid) {
                    instance.put('/AssetProject/Put',this.basicdata).then(res=>{ 
                        if(res.data.success){
                            this.$message({
                                message: '编辑成功',
                                type: 'success'
                            });
                            this.getpackagedetail(this.$route.params.id);//获取资产包详情
                            this.dialogFormVisible=false   
                        }else{
                            this.$message.error('编辑错误请联系管理员');
                        }                      
                    });
                } else {
                    this.$message.error('请正确填写信息');
                    }
                });             
            },
            cancelbj(){
                this.editstate=false;
                this.getpackagedetail(this.$route.params.id)//获取资产包详情
            },
            //查看
            handleClick(row) {
                var url =`/modules/promanage1/household/${row.Id}`; 
                this.$router.replace(url);
            },
            //删除
            handleDelete(index, row){ 
                var id =row.Id;
                this.$confirm('您确定删除编号为：'+row.Code+'的债券户吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                })
                .then(() => {
                    
                    instance.delete('/AeestFamily/Delete?id='+id).then(res=>{
                        if(res.data.success){
                            this.tableData.splice(index, 1);
                            this.$message({
                                type: 'success',
                                message: '删除成功!'
                            });
                            //操作日志
                            let param = {  ExternalId: res.data.response,Name:row.Name+"-删除" };
                           addSysOperateLog(param);
                        }else{
                            this.$message({
                            type: 'info',
                            message: res.data.msg
                        });          
                        }
                    }); 
                 
                })
                .catch(() => {
                        this.$message({
                            type: 'info',
                            message: '已取消删除'
                        });          
                }); 
            },
            //分页
            //当前加载页 改变时会触发
            handleCurrentChange(val) {
                this.paging.currentPage=val
                this.getAeestFamilylist(); 
            },
            handleSelectionChange(rows){
                this.ids=[];
                rows.forEach(res=>{
                    this.ids.push(res.Id);
                });                    
            },
        },
        watch: {
            $route: {
                handler(to, from) { 
                   this.$route.params.id = to.params.id;
                   this.getpackagedetail(this.$route.params.id)//获取资产包详情  
                   this.getAeestFamilylist();   
                },
                deep:true
            }
        }
    }
</script>
<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
/* input[type="number"] {
  -moz-appearance: textfield;
} */
</style>
<style lang="less" scoped>
    .mianbox{
        padding: 1.5rem;
        background-color: #f6f8f9;
        height: 100%;
        box-sizing: border-box;
        .topbox{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 2rem;
            .topbox_left{
                font-size: 2rem;
                font-weight: 600;
                color: #333333;
                .el-icon-d-arrow-left{
                    cursor: pointer;
                    padding-right: 1rem;
                }
            }
            .topbox_center{
                font-size: 1.4rem;
                color: #999999;
            }
        }
        .Basicinforbox{
            padding: 2rem;
            background-color: #ffffff;
            box-shadow: 0 0 0.6rem #e0e0e0;
            border-radius:0.5rem ;
            margin-top: 1.5rem;
        }
        .householdlistbox{
            padding: 2rem;
            background-color: #ffffff;
            box-shadow: 0 0 0.6rem #e0e0e0;
            border-radius:0.5rem ;
            margin-top: 1.5rem;
            .nowtopbox{
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .biaoti{
                    font-size: 1.6rem;
                    font-weight: 600;
                    color: #333333;
                }
            }
            .listbox{
                margin-top: 2rem;
            }
        }
    }
</style>